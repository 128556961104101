import React from "react";
import { Navigation } from "./navigation";
import { routes } from "./routes";
import { useMedia } from "./breakpoints";
import {Grid, Image } from "semantic-ui-react";
export const Home = () => {
  const isTabletMin = useMedia("TabletMin");
  return (
    <>
      
      <section className = "styles-container styles-display-container" style = {{height:"600px"}}>
      <video data-aid="HEADER_VIDEO"
          poster="https://img1.wsimg.com/isteam/videos/gyRNYge" autoPlay loop muted playsInline
          style={{ width: "100%", display: "block", position: "absolute", top: "50%", left: "50%", transform: "translateX(-50%) translateY(-50%)", height: "auto", minHeight: "100%", objectFit: "cover" }}
          src="https://websites.godaddy.com/categories/v4/videos/raw/video/gyRNYge">
        </video>

        <div className = "styles-text-white styles-center" id="home" style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%,-50%)", textAlign:"center", zIndex:"1"}}>
          <h1 style={{ fontSize: "50px", WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor:"#051336" }}>
            Misty's Web Portfolio
          </h1>
        </div>
      </section>
      <section className="styles-container "
        style={{ padding: "80px 16px" }}
        id="about"
      >
        <div className = "styles-text-black style-center">
          <h2 className = "styles-center">About me</h2>
          <div className="styles-row-padding styles-center" style={{ marginTop: 64 }}>
          <h1 className = "styles-centered style-textarea" style = {{justifyContent:"center"}}>I am an undergrad student pursuing a bachelor's degree in Computer Engineering, Electrical Engineering, and Computer Science. 
              <br /> Louisiana State University: 5th year </h1>
          </div>
        </div>

      </section>
      <section >
        
        <div className="styles-container styles-center styles-blue-background" style={{ padding:"16px"}}>
          <Grid doubling columns={3}>
            <Grid.Column>
              <h4>Achievements</h4>
            </Grid.Column>
            <Grid.Column>
              <h4>Strengths</h4>
            </Grid.Column>
            <Grid.Column>
              <h4>Goals</h4>
            </Grid.Column>
          </Grid>
        </div>
      </section>
    </>
    
  );
};
