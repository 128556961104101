import React, {Component} from "react";
import { Navigation } from "../navigation";
import { routes } from "../routes";
import { useMedia } from "../breakpoints";
import {Card, Grid, GridColumn, GridRow, Image } from "semantic-ui-react";
export const Electrical = () => {
    const isTabletMin = useMedia("TabletMin");
    return (
      <>
        <section className = "styles-container styles-display-container" style={{paddingTop:"53%"}}>
          <video data-aid="HEADER_VIDEO"
            poster="https://img1.wsimg.com/isteam/videos/Z1pq20k"
            autoPlay loop muted playsInline
            style={{ width: "100%", display: "block", position: "absolute", top: "50%", left: "50%", transform: "translateX(-50%) translateY(-50%)", height: "auto", minHeight: "100%", objectFit: "cover" }}
            src="https://websites.godaddy.com/categories/v4/videos/raw/video/Z1pq20k" >
          </video>
          <div className = "styles-text-white styles-center" id="home" style={{position:"absolute", top:"25%", left:"50%", transform:"translate(-50%,-50%)", textAlign:"center", zIndex:"1"}}>
            <h1 style={{ fontSize: "50px", WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor:"#051336" }}>Electrical Engineering</h1>
        </div>
        </section>
        <section className="styles-container styles-display-container" style={{ paddingTop: "10%" }}>
          <div className="styles-text-black styles-center styles-centered" id="classes">
            <Grid columns={5} style={{ margin: 8 }}>
              <GridRow>
              <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Circuits 1</Card.Header>
                <Card.Meta>LSU EE 2120</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Circuits 2</Card.Header>
                <Card.Meta>LSU EE 2130</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Electronics 1</Card.Header>
                <Card.Meta>LSU EE 2230</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                </Card>
                </GridColumn>
              </GridRow>
              <GridRow>
              <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Electronics Lab</Card.Header>
                <Card.Meta>LSU EE 2231</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Digital Logic 1</Card.Header>
                <Card.Meta>LSU EE 2741</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Digital Logic 2</Card.Header>
                <Card.Meta>LSU EE 2742</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                </Card>
                </GridColumn>
              </GridRow>
              <GridRow>
              <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Tools</Card.Header>
                <Card.Meta>LSU EE 2810</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Probability</Card.Header>
                <Card.Meta>LSU EE 3150</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Communications In Computing</Card.Header>
                <Card.Meta>LSU EE 3710</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                </Card>
                </GridColumn>
              </GridRow>
              <GridRow>
              <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Microprocessor Systems</Card.Header>
                <Card.Meta>LSU EE 3752</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                  <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Computer Organization</Card.Header>
                <Card.Meta>LSU EE 3755</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Electric and Magnetic Fields</Card.Header>
                <Card.Meta>LSU EE 3320</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                </Card>
                </GridColumn>
                </GridRow>
            </Grid>
          </div>
        </section>
      </>
  )
}