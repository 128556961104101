export const rootRoutes = {
    home: "/"
};

export const routes = {
    home: {
        achievements: `${rootRoutes.home}achievements`,
        cs: `${rootRoutes.home} computerScience`,
        electrical: `${rootRoutes.home} electrical`,
        spanish: `${rootRoutes.home} spanish`,
    }
};