import React from "react";
import { Navigation } from "../navigation";
import { routes } from "../routes";
import { useMedia } from "../breakpoints";
import {Card, Grid, GridColumn, GridRow, Image } from "semantic-ui-react";
export const ComputerScience = () => {
    const isTabletMin = useMedia("TabletMin");
    return (
        <>
        <section className = "styles-container styles-display-container " style={{paddingTop:"53%"}}>
          <video data-aid="HEADER_VIDEO"
            className="styles-overlay"
            poster="https://img1.wsimg.com/isteam/videos/ubwAw3P1NGSn7Qly4"
            autoPlay loop muted playsInline
            style={{ width: "100%", display: "block", position: "absolute", top: "50%", left: "50%", transform: "translateX(-50%) translateY(-50%)", height: "auto", minHeight: "100%", objectFit: "cover" }}
            src="https://categories.api.godaddy.com/v4/videos/raw/video/ubwAw3P1NGSn7Qly4">
          </video>
        <div style={{position:"absolute", top:"25%", left:"50%", transform:"translate(-50%,-50%)", textAlign:"center", zIndex:"1"}} id="home">
          <h1 style={{ fontSize: "50px", WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor:"#051336" }}>Computer Science</h1>
        </div>
        </section>
        <section className="styles-container styles-display-container" style={{ paddingTop: "10%" }}>
          <div className="styles-text-black styles-center styles-centered" id="classes">
            <Grid columns={5} style={{ margin: 8 }}>
              <GridRow>
              <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Java 1</Card.Header>
                <Card.Meta>LSU CSC 1350</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Java 2</Card.Header>
                <Card.Meta>LSU CSC 1351</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Discrete Structures</Card.Header>
                <Card.Meta>LSU CSC 2259</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Numerical Methods </Card.Header>
                <Card.Meta>LSU CSC 2262</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Intro To CyberSecurity</Card.Header>
                <Card.Meta>LSU CSC 2362</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Advanced Data Structures and Algorithmic Analysis</Card.Header>
                <Card.Meta>LSU CSC 3102</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Intro to Systems Programming</Card.Header>
                <Card.Meta>LSU CSC 3304</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Object Oriented Design</Card.Header>
                <Card.Meta>LSU CSC 3380</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Computer Organization</Card.Header>
                <Card.Meta>LSU CSC 3501</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Programming Languages</Card.Header>
                <Card.Meta>LSU CSC 4101</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Operating Systems</Card.Header>
                <Card.Meta>LSU CSC 4103</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Software Systems Development</Card.Header>
                <Card.Meta>LSU CSC 4330</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Software Vulnerabilities</Card.Header>
                <Card.Meta>LSU CSC 4362</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>
                    have not taken
                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Intro to Database Management Systems</Card.Header>
                <Card.Meta>LSU CSC 4402</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>
                  not yet taken
                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Computer Networks</Card.Header>
                <Card.Meta>LSU CSC 4501</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>
                  in progress
                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
              </GridRow>
              <GridRow>
              <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>CyberForensics</Card.Header>
                <Card.Meta>LSU CSC 2700</Card.Meta>
                <Card.Description>
                      Special topics
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Reverse Engineeringa and Malware Analysis</Card.Header>
                <Card.Meta>LSU CSC 4360</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
                <Card>
              <Card.Content>
                <Card.Header>Mobile Security and Applied Cryptography</Card.Header>
                <Card.Meta>LSU CSC 4562</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>
                  not yet taken
                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
              </GridRow>
            </Grid>
            </div>
                </section>
        </>
  )
}