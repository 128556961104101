import React from 'react';

import './App.css';
import { Route, Routes } from "react-router-dom";
import { rootRoutes, routes } from "./routes";
import { Home } from "./home";
import { Navigation } from "./navigation";
import { Achievements } from "./achievements";
import { ComputerScience } from "./classes/cs";
import { Electrical } from "./classes/electrical";
import { Spanish } from "./classes/spanish";

function App() {
  return (
    <div>
      <Navigation/>
      <Routes>
        <Route path={rootRoutes.home} element={<Home />} />
        <Route path={routes.home.achievements} element={<Achievements />} />
        <Route path={routes.home.cs} element={<ComputerScience />} />
        <Route path={routes.home.electrical} element={<Electrical />} />
        <Route path = {routes.home.spanish} element = {<Spanish/>}/>
          
        
      </Routes>
    </div>
  );
}

export default App;
