import React from "react";
import { Navigation } from "./navigation";
import { routes } from "./routes";
import { useMedia } from "./breakpoints";
import {Grid, Image } from "semantic-ui-react";
export const Achievements = () => {
    const isTabletMin = useMedia("TabletMin");
    return (
        <>
      <section>
        <div
          className = "bgimg-1 styles-text-white styles-center"
          id="home">
          <h1 className="styles-centered" style={{ justifyContent:"center", paddingTop:"20%"}}>Achievements</h1>
        
        </div>
        </section>
            <section className = "styles-blue-background" style = {{paddingTop:"6%", paddingBottom: "10%", paddingRight:"10%", paddingLeft:"10%"}}>
                <h1 className="styles-center">Experiences</h1>
                <Grid doubling columns = {2}>
                    <Grid.Column className = "styles-padding-16">
                        <Grid.Row>
                            <h2 className="styles-center">  KUKA Robotics </h2>
                            
                        </Grid.Row>
                        <Grid.Row>
                            <h4 className="styles-center"> Kuka robotics is a company based in Germany.
                                I was able to tour this company and learn valuable information while taking an Engineering class in Germany.
                                Companies from BMW, a car manufacturer, to Erdinger, a brewery, uses these robots to efficiently produce their products.
                            </h4>
                        </Grid.Row>

                    </Grid.Column>
                    <Grid.Column className = "styles-padding">
                    <Grid.Row>
                            <h2 className="styles-center">  Observatory </h2>
                            
                        </Grid.Row>
                        <Grid.Row>
                            <h4 className="styles-center">AIAA (American Institute of Aeronautics and Astronautics)
                                took an educational trip to McDonald's Observatory in Fort Davis, Texas.
                                During this trip, I attended several presentations regarding our solar system.
                                I learned about largest telescope in the world, the Hobby- Elberly Telescope, in a hands-on manner.
</h4>
                        </Grid.Row>

                    </Grid.Column>
                </Grid>
                
        </section>
</>
    );
};