import React, { useContext, useState } from "react";
import { HashLink } from 'react-router-hash-link';
import { Menu, Image, Sidebar, Dropdown, DropdownItem, DropdownMenu } from "semantic-ui-react";
import "./assets/style.css";
import { NavLink, useNavigate } from "react-router-dom";
import { rootRoutes, routes } from "./routes";
import { useMedia } from "./breakpoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaGithub, FaBook } from "react-icons/fa";
import { GrAchievement } from "react-icons/gr";
import {
  faUser,
  faDollarSign,
  faEnvelope,
  faBars,
  faMultiply,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
export const Navigation = () => {
  const isTabletMin = useMedia("TabletMin");

    
    return (
        // <div className="styles-top" style={{ paddingBottom: "1%" }}>
      <>{ isTabletMin ? <DesktopNavigation /> : <MobileNavigation />}</>
    // </div>
    );
};
const DesktopNavigation = () => {
    const navigate = useNavigate();
  return (
    <>
      <Menu
        position ="right"
        style={{position:"sticky", top:"0%", textAlign:"center", zIndex:"2", background:"white"}}>
          <Menu.Item
            as={HashLink}
            to={`${rootRoutes.home}#about`}
            className="styles-bar-item styles-button"
            style={{ color: "black" }}>
            ABOUT
          </Menu.Item>
          <Menu.Item
          style={{ color: "black" }}>
         <FaBook/>
          <Dropdown pointing text='CLASSES' className ="link item" >
            <Dropdown.Menu vertical style={{zIndex:"1s"}} >
              <Dropdown.Item
                as={NavLink}
                to={routes.home.cs}>
                Computer Science
              </Dropdown.Item>
              <Dropdown.Item
                as={NavLink}
                to={routes.home.electrical}>
                Electrical Engineering
              </Dropdown.Item>
              <Dropdown.Item
                as={NavLink}
                to={routes.home.spanish}> 
                Spanish
              </Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
          <Menu.Item
            className="styles-bar-item styles-button"
            style={{ color: "black" }}
        >
          <Link className = "styles-text-black" to = {"https://github.com/mistyburrow"}>
          <FaGithub  style={{ paddingRight: 3 }}/> 
            GITHUB
          </Link>
          </Menu.Item>
          <Menu.Item
            className="styles-bar-item styles-button"
            style={{ color: "black" }}
          >
            <Link className ="styles-text-black" to={"https://drive.google.com/file/d/19fuTgBIKvNfQKY7SviWjC3r5kqetRU3Q/view?usp=drive_link"}>
            <FontAwesomeIcon 
                icon={faPaperclip} style={{ paddingRight: 3 }}
              /> RESUME
            </Link>
          </Menu.Item>
          <Menu.Item
            as={NavLink}
            to={routes.home.achievements}
            className="styles-bar-item styles-button"
            style={{ color: "black" }}
          >
            <GrAchievement style={{ paddingRight: 3 }}/>
            ACHIEVEMENTS
          </Menu.Item>
          
        </Menu>
    </>
  );
};
const MobileNavigation = () => {
    const [visible, setVisible] = useState(false);
  const [icon, setIcon] = useState(<FontAwesomeIcon icon={faBars} />);
    const navigate = useNavigate();
     const hideSidebar = () => {
         setIcon(<FontAwesomeIcon icon={faBars} />);
         setVisible(false);
       };
       const showSidebar = () => {
         setIcon(<FontAwesomeIcon icon={faMultiply} />);
        setVisible(true);
       };
    
       const toggleSidebar = () => {
         visible ? hideSidebar() : showSidebar();
       };
    
  return (
    <>
      <Menu
        style={{ position: "sticky", textAlign: "left", zIndex: "2", background: "transparent", top:"0%" }}>
      <Menu.Menu>
          <Menu.Item style={{ color: "black" }} onClick={toggleSidebar}>
            {icon}
          </Menu.Item>
        </Menu.Menu>
        <Sidebar as={Menu} animation="overlay" icon="labeled" vertical
          visible={visible} onHide={() => {
            setVisible(false);
            toggleSidebar();
          }}
          width="thin"
          direction="left"
          style={{ backgroundColor: "transparent", zIndex:"1", paddingTop:"10%"}}
        >
          <Menu.Item
            as={HashLink}
            to={`${rootRoutes.home}#about`}
            className="styles-bar-item styles-button"
            style={{ color: "black" }}
          >
            ABOUT
          </Menu.Item>
          <Menu.Item
            style={{ color: "black" }}>
          <Dropdown pointing text='CLASSES' className ="link item" >
            <Dropdown.Menu vertical style={{zIndex:"1s"}} >
              <Dropdown.Item
                as={NavLink}
                to={routes.home.cs}>
                Computer Science
              </Dropdown.Item>
              <Dropdown.Item
                as={NavLink}
                to={routes.home.electrical}>
                Electrical Engineering
              </Dropdown.Item>
              <Dropdown.Item
                as={NavLink}
                to={routes.home.spanish}> 
                Spanish
              </Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
          <Menu.Item
            as={HashLink}
            to={`${rootRoutes.home}#gitHub`}
            className="styles-bar-item styles-button"
            style={{ color: "black" }}
          >
           
            GITHUB
          </Menu.Item>
          <Menu.Item
            className="styles-bar-item styles-button"
            style={{ color: "black" }}
          >
            <Link to={"https://drive.google.com/file/d/19fuTgBIKvNfQKY7SviWjC3r5kqetRU3Q/view?usp=drive_link"}>
              RESUME
            </Link>
          </Menu.Item>
          <Menu.Item
            as={NavLink}
            to={routes.home.achievements}
            className="styles-bar-item styles-button"
            style={{ color: "black" }}
          >
            Achievements
          </Menu.Item>
        </Sidebar>
      </Menu>
    </>
  );
};