import React from "react";
import { Navigation } from "../navigation";
import { routes } from "../routes";
import { useMedia } from "../breakpoints";
import {Card, Grid, GridColumn, GridRow, Image } from "semantic-ui-react";
export const Spanish = () => {
    const isTabletMin = useMedia("TabletMin");
    return (
        <>
             <section>
        <div
          className = "bgimg-1 styles-text-white styles-center"
          id="home">
          <h1 className="styles-centered" style={{ justifyContent:"center", paddingTop:"20%"}}>Spanish</h1>
        
        </div>
        </section>
        <section className="styles-container styles-display-container" style={{ paddingTop: "10%" }}>
        <div className="styles-text-black styles-center styles-centered" id="classes">
            <Grid columns={5} style={{ margin: 8 }}>
              <GridRow>
              <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Elementary Spanish</Card.Header>
                <Card.Meta>LSU SPAN 1101</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Intensive-Beginner Spanish</Card.Header>
                <Card.Meta>LSU SPAN 1152</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Intermediate Spanish</Card.Header>
                <Card.Meta>LSU SPAN 2101</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={5}>
                  
            <Card>
              <Card.Content>
                <Card.Header>Intermediate Spanish 2</Card.Header>
                <Card.Meta>LSU SPAN 2102</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Spanish Langage and Culture</Card.Header>
                <Card.Meta>LSU SPAN 2155</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Interm Oral Communication</Card.Header>
                <Card.Meta>LSU SPAN 2156</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
              </GridRow>
              <GridRow>
              <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Spanish Grammer and Composition</Card.Header>
                <Card.Meta>LSU SPAN 3010</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Advanced Oral Communication 2</Card.Header>
                <Card.Meta>LSU SPAN 3015</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Literary Analysis</Card.Header>
                <Card.Meta>LSU SPAN 3020</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width = {5}>
            <Card>
              <Card.Content>
                <Card.Header>Spanish Phonetics</Card.Header>
                <Card.Meta>LSU SPAN 4602</Card.Meta>
                <Card.Description>
                      info about class teachings
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <a>

                </a>
              </Card.Content>
                  </Card>
                </GridColumn>
              </GridRow>
              </Grid>
                </div>
          
        </section>
        </>
  )
}